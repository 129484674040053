import { useParams } from 'react-router';
import MarkdownToJsx from 'markdown-to-jsx';
import raw from 'raw.macro';
import React from 'react'
import './AppPage.scss';
const termsAndConditionsMd = raw('../../content/terms-and-conditions.md');
const privacyPolicyMd = raw('../../content/privacy-policy.md');

const AppPage = () => {

  const { slug, app } = useParams();

  const appName = app => {
    switch (app) {
      case 'calmer-baby':
        return 'Calmer Baby';
      default: 
        return 'Application';
    }
  }

  const Markdown = ({source}) => <MarkdownToJsx options={{overrides: {
    appName: props => <span>{appName(app)}</span>,
  }}}>{source}</MarkdownToJsx>;

  const privacyPolicy = {
    title: 'Privacy Policy',
    body: <Markdown source={privacyPolicyMd} />
  }

  const termsAndConditions = {
    title: 'Terms & Conditions',
    body: <Markdown source={termsAndConditionsMd} />
  }

  const page = slug => {
    switch (slug) {
      case 'privacy-policy':
        return privacyPolicy;
      case 'terms-and-conditions':
        return termsAndConditions;
      default:
        return {
          title: 404,
        };
    }
  }

  const { title, body } = page(slug);

  return <article className="article">
    <h1>{title}</h1>
    {body}
  </article>
};

export default AppPage;