import React from 'react';
import { Link } from 'react-router-dom';
import './Navigation.scss';

const Navigation = () => {

  return <nav className="navigation">
    <ul>
      <li><Link to="/" className="nav-item">Home</Link></li>
      <li><Link to="/apps" className="nav-item">Apps</Link></li>
      <li><Link to="/contact" className="nav-item">Contact</Link></li>
    </ul>
  </nav>
}

export default Navigation;