import React from 'react'
import './Hero.scss';

const Hero = ({ title, children }) => {
  return <div className="hero">
    <h1 className="heading">{title}</h1>
    <p className="body">{children}</p>
  </div>

};

export default Hero;