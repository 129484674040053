import React from 'react';
import { useLocation } from 'react-router';
import './Page.scss';
import Header from '../Header';

const Page = props => {
  const location = useLocation();

  const bg = location => {
    let color = '';

    switch (location.pathname) {
      case '/apps':
        color = '#FDF0EC';
        break;
      case '/contact':
        color = '#D1E1E0';
        break;
      default:
        color = 'transparent';
    }

    return {
      style: {
        backgroundColor: color,
      }
    }
  };

  return (<div className="page" {...bg(location)}>
    <Header />

    <main className="main">
      {props.children}
    </main>
    <footer className="footer">
      <span className="copyright">{new Date().getFullYear()} &copy; Copyright All rights reserved CodePitcher AB</span>
    </footer>
  </div>);
}

export default Page;