import React from 'react'
import logo from './logo.svg';
import Navigation from '../Navigation';
import './Header.scss';

const Header = () => {
  return <header className="App-header">
    <img src={logo} className="App-logo" alt="logo" />
    <Navigation />
  </header>
};

export default Header;