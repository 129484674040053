import React from 'react';

import { BrowserRouter as Router, Route, Link, Switch } from 'react-router-dom';
import { AnimatedSwitch } from 'react-router-transition';
import './App.scss';
import Home from './pages/Home';
import Apps from './pages/Apps';
import Page from './components/Page';
import Contact from './pages/Contact';
import AppPage from './pages/AppPage';

const App = () => {

  return (
    <Router>
      <Page>
        <Switch>
          <Route path="/apps/:app/:slug" render={props => <AppPage {...props} />} />
          <Route path="/apps" render={props => <Apps {...props} />} />
          <Route path="/contact" render={props => <Contact {...props} />} />
          <Route path="/" render={props => <Home {...props} />} />
        </Switch>
      </Page>
    </Router>
  );
}

export default App;
